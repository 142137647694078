import { log } from "dcmjs";
import { enviroment } from "../config/enviroment";
import uids from "../utils/uids";
import { getTags } from "./tagsDescriptions";


import * as cornerstone from "@cornerstonejs/core"

export const handleimages = async images => {
  console.log("handleee",images)
  let imageList = {}
  let series = []

  const { type_study } = images
  if(images.sicem){
    // console.log(images)
    let _ = `${images.enviroment}/${images.path}/`
        for (const iterator of images.images) {
          series.push(Object.keys(iterator)[0])
          for (const i of iterator[Object.keys(iterator)]) {
            imageList[Object.keys(iterator)] && imageList[Object.keys(iterator)].length >= 1 ? imageList[Object.keys(iterator)].push(`wadouri:${_}${i}`) : imageList[Object.keys(iterator)] = [`wadouri:${_}${i}`]
          }
        }
  }else if(images.puebla){
    let _ = `${images.enviroment}/${images.path}/`
    for (const iterator of images.images) {
      series.push(Object.keys(iterator)[0])
      for (const i of iterator[Object.keys(iterator)]) {
        imageList[Object.keys(iterator)] && imageList[Object.keys(iterator)].length >= 1 ? imageList[Object.keys(iterator)].push(`wadouri:${_}${i}`) : imageList[Object.keys(iterator)] = [`wadouri:${_}${i}`]
      }
    }
  }else if(images.newPuebla){
    if(images.newStructure){
      let _ = `${enviroment.pacsNewPuebla}${images.path}/`
      for (const iterator of images.images) {
        series.push(Object.keys(iterator)[0])
        for (const i of iterator[Object.keys(iterator)]) {
          try {
            // await cornerstone.imageLoader.loadAndCacheImage(`wadouri:${_}${i}`)
            imageList[Object.keys(iterator)] && imageList[Object.keys(iterator)].length >= 1 ? imageList[Object.keys(iterator)].push(`wadouri:${_}${i}`) : imageList[Object.keys(iterator)] = [`wadouri:${_}${i}`]
          } catch (error) {
            console.log(error)
          }
          
        }
      }
    }
    if (!images.newStructure){
      // if(images.type_study == 'MG'){
        let _ = `${enviroment.pacsNewPuebla}${images.path}/`
        for (const iterator of images.images) {
          series.push(Object.keys(iterator)[0])
          for (const i of iterator[Object.keys(iterator)]) {
            imageList[Object.keys(iterator)] && imageList[Object.keys(iterator)].length >= 1 ? imageList[Object.keys(iterator)].push(`wadouri:${_}${i}`) : imageList[Object.keys(iterator)] = [`wadouri:${_}${i}`]
          }
        }
      // }
      // else{
      //   let data = await getSeries({ path :images.path, list : images.images, env: enviroment.pacsNewPuebla })
      //   imageList = data.imageList
      //   series = data.series
      //   for (const i of series) {
      //     imageList[i] = imageList[i].sort((a, b) => compareUrls(a ,b))
      //   }
      // }
    } 
  }else{
    // innova
    if(images.newStructure){
      let _ = `${enviroment.pacs}${images.path}/`
      for (const iterator of images.images) {
        series.push(Object.keys(iterator)[0])
        for (const i of iterator[Object.keys(iterator)]) {
          try {
            // await cornerstone.imageLoader.loadAndCacheImage(`wadouri:${_}${i}`)
            imageList[Object.keys(iterator)] && imageList[Object.keys(iterator)].length >= 1 ? imageList[Object.keys(iterator)].push(`wadouri:${_}${i}`) : imageList[Object.keys(iterator)] = [`wadouri:${_}${i}`]
          } catch (error) {
            console.log(error)
          }
          
        }
      }
    }
    if (!images.newStructure){
      if(images.type_study == 'MG'){
        let _ = `${enviroment.pacs}${images.path}/`
        for (const iterator of images.images) {
          series.push(Object.keys(iterator)[0])
          for (const i of iterator[Object.keys(iterator)]) {
            imageList[Object.keys(iterator)] && imageList[Object.keys(iterator)].length >= 1 ? imageList[Object.keys(iterator)].push(`wadouri:${_}${i}`) : imageList[Object.keys(iterator)] = [`wadouri:${_}${i}`]
          }
        }
      }else{
        let data = await getSeries({ path :images.path, list : images.images})
        imageList = data.imageList
        series = data.series
        for (const i of series) {
          imageList[i] = imageList[i].sort((a, b) => compareUrls(a ,b))
        }
      }
    } 
  }

  return {
    imageList,
    series,
    type_study,
  };
};

const  compareUrls=(url1, url2) =>{
  const numericPart1 = parseInt(url1.match(/\.(\d+)\.jpeg$/)[1]);
  const numericPart2 = parseInt(url2.match(/\.(\d+)\.jpeg$/)[1]);
  return numericPart1 - numericPart2;
}


const group = async data =>{

  const { path ,list } = data
  const imageList = {}
  let series = []
  let url = `${enviroment.pacs}${path}/`
  for (const iterator of list) {
    let urlImage = `wadouri:${url}${iterator}`
    const parts = iterator.split('.').slice(0, -1); // Remove the extension
    const key = parts[1] 
    if (!imageList[key]) {
      imageList[key] = [];
  }
  
  imageList[key].push(urlImage);
    
  }
  for (const key in imageList) {
    series.push(key)
  }
  return {imageList,series}
  
}


const getSeries = async (data) =>{
  console.log("init process get series")
  const imageList = {}
  let series = []
  const { path ,list } = data
  let url =  data.env ? `${data.env}${path}/` : `${enviroment.pacs}${path}/`
  for (const iterator of list) {
    let urlImage = `wadouri:${url}${iterator}`
      try {
        const cache = await cornerstone.imageLoader.loadImage(urlImage)
        cache.windowCenter =  2048
        cache.windowWidth =  4096
        // console.log(cache.data)
        const seriesNumber =  cache.data.intString('x00200011') != undefined ? cache.data.intString('x00200011') : cache.data.string('x0020000e');
        const instanceNumber = cache.data.intString('x00200013') != undefined ? cache.data.intString('x00200013') : cache.data.intString('x00200013');
        if (!imageList[seriesNumber]) imageList[seriesNumber] = []
        imageList[seriesNumber][instanceNumber -1]=urlImage
        console.log(`%cWaiting for serie ${seriesNumber} %c☕️`,'color: gray; font-size: 20px;', 'font-size: 40px;')
        
      } catch (error) {
        console.log(error)
      }
  }

  for (const key in imageList) {
    series.push(key)
  }
  // cornerstone.imageLoader.unregisterAllImageLoaders()
  return {imageList,series}
  

}



export const getTransferSyntax = (image) => {
  const value = image.data.string("x00020010");
  return value + " [" + uids[value] + "]";
};

export const getSopClass = (image) => {
  const value = image.data.string("x00080016");
  return value + " [" + uids[value] + "]";
};

export const getPixelRepresentation = (image) => {
  const value = image.data.uint16("x00280103");
  return value === undefined
    ? ""
    : value + (value === 0 ? " (unsigned)" : " (signed)");
};

export const getPlanarConfiguration = (image) => {
  const value = image.data.uint16("x00280006");
  return value === undefined
    ? ""
    : value + (value === 0 ? " (pixel)" : " (plane)");
};

export const getDescription = (image,descriptions,index,instanceNumber,wl) => {
  if (image && image.data) {
    let date = image.data.string("x00080020");
    let time = image.data.string("x00080030");

    const descriptionImage = {
      patientName: image.data.string("x00100010").replace(/\^/g, "\xa0\xa0"),
      instanceNumber: image.data.intString("x00200013"),
      hospital: image.data.string("x00080080"),
      gender: image.data.string("x00100040"),
      accession_number: image.data.string("x00100020"),
      numberUnk: image.data.string("x00200010"),
      unk: image.data.string("x00081030"),
      station: image.data.string("x00081010"),
      brand: image.data.string("x00080070"),
      type_study: image.data.string("x00080060"),
      windowingValue: {
        ww: wl ? wl.windowWidth : image.windowWidth,
        wc: wl ? wl.windowCenter : image.windowCenter,
      },
    };
    if (time)
      descriptionImage["date"] = `${date.substring(6, 8)}/${date.substring(4,6)}/${date.substring(0, 4)}   ${time.match(/\d{2}/g).join(":")}`;
    return descriptionImage;
  } else {
    const descriptionImage  = descriptions[index]
    if(descriptionImage)  descriptionImage['instanceNumber'] = instanceNumber
    return descriptionImage
  }
};

export const tags = async (image) => {
  const TAGS = await getTags(image.data);
  return TAGS;
};

export const getPosition = (orientation)=>{
  let position;
  switch (orientation) {
    case 'P\\L':
    case 'A\\R':
      position = 'CC'
      break;
    case 'P\\FL':
    case 'A\\FR':
      position = 'MLO'
      break;
    case 'P\\F':
    case 'A\\F':
      position = 'ML'
      break;

    default:
      break;
  }
  return position
}

export const positionMg = (data) => {
  const { position, laterality } = data;
  if ((position === "CC") & (laterality === "R")) {
    return 0;
  } else if ((position === "CC") & (laterality === "L")) {
    return 1;
  } else if ((position === "MLO") & (laterality === "R")) {
    return 2;
  } else if ((position === "MLO") & (laterality === "L")) {
    return 3;
  }
};

// Puebla functions 
export const fetchImagesFromPuebla = async (data) => {
  const transferId = data.replace('new_puebla_', '')
  const url = `${enviroment.pueblaApi}/transfers/${transferId}/images`
  const response = await fetch(url)
  return response.json()
}

// functions for sicem 

export const getServer = (data) =>{
  var arr = data.split('_')
  const state =  arr[1]
  const path  = arr[2].split('.')[0]
  let  arrImages = arr.slice(2); 
  
    const servers = {
    15 : 'https://aloim1.sicemx.com/PacsSicem',
    9 : 'https://aloim1.sicemx.com/PacsSicem',
    20 : 'https://aloim2.sicemx.com/PacsSicem',
    99 : 'https://aloim4.sicem.mx/PacsSicem'

  }

  const dataImages ={
    type_study : 'MG',
    path: path,
    images : [],
    sicem : true,
    enviroment : servers[state]
  } 


  for (let index = 0; index < arrImages.length; index++) {
    let image = arrImages[index];
    let serie = index + 1
    image = image.replace('dcm','jpeg')
    dataImages.images.push({ [serie] :[image]})
    
  }
  
  return dataImages
}



export const decodeData = endoceData =>{
  const data = endoceData.split('external_env')[1]  
  const dcoD = JSON.parse(decodeURIComponent(data))
  let images = dcoD.images
  dcoD.images = []
  dcoD.enviroment = `https://${dcoD.enviroment}`

  for (let index = 0; index < images.length; index++) {
    let image = images[index];
    let serie = index + 1
    dcoD.images.push({ [serie] :[image]})
    
  }
  return dcoD

}


